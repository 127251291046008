import { PLATFORM } from 'aurelia-pal';
import { Notifier } from 'common/ui';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { LandingPageEditor } from './dialog/landing-page-editor';
import { DialogService } from 'aurelia-dialog';
import environment from '../../../config/environment.json';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./dialog/landing-page-editor');

export class LandingPageList {
    static inject = [Notifier, Api, I18n, DialogService];

    landingPageType = 'Webinar';
    landingPages = [];
    editLandingPage;
    _loadKey;

    constructor(notifier, api, i18n, dialogService) {
        this._notifier = notifier;
        this._api = api;
        this._i18n = i18n;
        this._dialogService = dialogService;
    }

    async attached() {
        try {
            const secretData = await this._api.get('landing-page-secret');
            this.secret = secretData.secret;
            this._load();
        } catch (err) {
            console.log(err);
        }
    }

    setType(type) {
        if (type === this.landingPageType) return;
        this.landingPageType = type;
        this._load();
    }

    async _load() {
        this.landingPages = [];

        try {
            this.landingPages = await this._api.get(`landing-page/${encodeURIComponent(this.landingPageType)}`);
            this.editLandingPage = undefined;
            if (!this.landingPages.length) return;
            this.landingPages = this.landingPages.sort((a,b) => a.key.localeCompare(b.key));
            if (!this._loadKey) this._loadKey = 'template';
            let found = false;
            for (let lp of this.landingPages) {
                if (this._loadKey !== lp.key) continue;
                this.display(lp);
                found = true;
            }
            if (!found) this.display(this.landingPages[0]);
        } catch (err) {
            console.log(err);
        }
    }

    deleteLandingPage() {
        const model = { key: 'delete-landing-page', okButtonClass: 'btn-danger', messageObject: { name: this.editLandingPage.key } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                const deleteResponse = await this._api.delete(`landing-page/${encodeURIComponent(this.landingPageType)}/${encodeURIComponent(this.editLandingPage.key)}`);
                if (deleteResponse.succeeded) {
                    await this._load();
                    return;
                }
                this._notifier.error(deleteResponse.message);
            } catch (err) {
                console.log(err);
            }
        });
    }

    display(landingPage) {
        this.editLandingPage = landingPage;
        if (this.landingPageType === 'AppyPie') {
            this.landingPageUrl = `${environment.url}/appypie.html?lpfn=${this.secret}#/${encodeURIComponent(this.editLandingPage.key)}`;
        } else {
            this.landingPageUrl = `${environment.url}/landing.html#/${encodeURIComponent(this.landingPageType)}/${encodeURIComponent(this.editLandingPage.key)}`;
        }
    }

    addLandingPage() {
        this._dialogService.open({ viewModel: LandingPageEditor, model: { type: this.landingPageType }, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            this._loadKey = response.output.key;
            this._load();
        });
    }
}
