import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Leads, LEAD_TYPE } from 'services/leads';
import { Geography } from 'services/geography';
import { c } from 'common/common';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';

PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class AutoAssignFilters {
    static inject = [DialogService, EventAggregator, Leads, Geography];
    _dialogService;
    _ea;
    _leads;
    _geograhy;

    filters = [];
    _handlers = [];

    constructor(dialogService, ea, leads, geograhy) {
        this._dialogService = dialogService;
        this._ea = ea;
        this._leads = leads;
        this._geograhy = geograhy;
    }

    attached() {
        this._load();
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this._saveOrdering(), 0);
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load(expandFilterId) {
        try {
            this.usStates = c.Geography.usStates;
            this.leadTypes = LEAD_TYPE.all();
            this.filters = await this._leads.filters();
            this.filters.forEach(f => {
                f.actions = [
                    { key: 'delete-filter', name: 'delete', icon: 'fa-duotone fa-trash-can' },
                ];
            });
            if (expandFilterId) {
                this.filters.find(x => x.id === expandFilterId).expanded = true;
            }
        } catch (err) {
            console.log(err);
        }
    }

    addFilter() {
        const model = { key: 'add-lead-auto-assign-filter', okButtonClass: 'btn-primary', inputLabel: 'name', expanded: true };
        this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled || !response.output.value) return;
            const data = await this._leads.saveFilter(undefined, response.output.value);
            console.log(data);
            this._load(data.id);
        });
    }

    filterAction(key, f, index) {
        switch (key) {
            case 'delete-filter': this._deleteFilter(f, index); break;
        }
    }

    async _deleteFilter(f, index) {
        try {
            await this._leads.deleteFilter(f.id);
            this.filters.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }

    async _saveOrdering() {
        try {
            const orderedIds = [];
            this.filters.forEach(f => orderedIds.push(f.id));
            await this._leads.setAutoAssignOrdering(orderedIds);
        } catch (err) {
            console.log(err);
        }
    }

    editName(f) {
        f.currentName = f.name;
        f.editingName = true;
    }

    cancelEditName(f) {
        f.name = f.currentName;
        f.currentName = undefined;
        f.editingName = false;
    }

    async saveName(f) {
        try {
            await this._leads.saveFilter(f.id, f.name);
            f.currentName = undefined;
            f.editingName = false;
        } catch (err) {
            console.log(err);
        }
    }

    async agentSelected(f, detail) {
        try {
            if (!detail) return;

            const model = { key: 'add-lead-auto-assign-agent', okButtonClass: 'btn-primary', inputLabel: 'lead-auto-assign-percentage', inputNumber: { min: 1, max: 100, step: 1 } };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled || !response.output.value) {
                    return;
                }
                const percentage = Number(response.output.value);
                const data = await this._leads.assignAgentToFilter(f.id, detail.id, percentage);
                f.assignedAgents.push({ memberId: detail.id, agent: data, percentage: percentage });
                f.addAgent = undefined;
            });
        } catch (err) {
            console.log(err);
        }
    }

    updateAssignedAgentPercentage(f, aa, index) {
        try {
            const model = {
                key: 'update-lead-auto-assign-agent',
                okButtonClass: 'btn-primary',
                inputLabel: 'lead-auto-assign-percentage',
                inputValue: aa.percentage,
                inputNumber: { min: 1, max: 100, step: 1 }
            };
            this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled || !response.output.value) {
                    return;
                }
                const percentage = Number(response.output.value);
                const data = await this._leads.assignAgentToFilter(f.id, aa.agent.id, percentage);
                f.assignedAgents[index].percentage = percentage;
            });
        } catch (err) {
            console.log(err);
        }
    }

    async removeAssignedAgent(f, aa, index) {
        try {
            f.assignedAgents.splice(index, 1);
            await this._leads.removeAgentFromFilter(f.id, aa.agent.id);
        } catch (err) {
            console.log(err);
        }
    }

    async usStateSelected(f) {
        try {
            if (!f.states.includes(f.addUsState)) f.states.push(f.addUsState);
            await this._saveFilter(f);
            f.closeUsStatePopover = new Date().valueOf();
            f.addUsState = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeUsState(f, index) {
        try {
            f.states.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    async usCountyStateSelected(f) {
        try {
            f.usCounties = await this._geograhy.usCounties(f.addUsCountyState);
        } catch (err) {
            console.log(err);
        }
    }

    async usCountySelected(f) {
        try {
            const index = f.counties.findIndex(x => x.fips === f.addUsCounty.fips);
            if (index < 0) {
                f.addUsCounty.displayName = `${f.addUsCounty.name} (${f.addUsCounty.usStateFips})`;
                f.counties.push(f.addUsCounty);
            }
            await this._saveFilter(f);
            f.closeUsCountyPopover = new Date().valueOf();
            f.addUsCountyState = undefined;
            f.usCounties = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeUsCounty(f, index) {
        try {
            f.counties.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    zipOpened(f) {
        f.focusZips = true;
    }

    zipClosed(f) {
        f.focusZips = false;
    }

    async zipEntered(f) {
        try {
            const zips = f.addZip.split(',');
            const addZips = JSON.parse(JSON.stringify(f.zipCodes));
            zips.forEach(z => {
                if (addZips.includes(z)) return;
                addZips.push(z);
            });
            f.zipCodes = addZips;
            await this._saveFilter(f);
            f.closeZipPopover = new Date().valueOf();
            f.addZip = undefined;
            f.focusZips = false;
        } catch (err) {
            console.log(err);
        }
    }

    async removeZip(f, index) {
        try {
            f.zipCodes.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    async removeAllZips(f) {
        try {
            f.zipCodes = [];
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    publisherIDOpened(f) {
        f.focusPublisherIDs = true;
    }

    publisherIDClosed(f) {
        f.focusPublisherIDs = false;
    }

    async publisherIDEntered(f) {
        try {
            const publisherIDs = f.addPublisherID.split(',');
            const addPublisherIDs = JSON.parse(JSON.stringify(f.publisherIDs));
            publisherIDs.forEach(x => {
                if (addPublisherIDs.includes(x)) return;
                addPublisherIDs.push(x);
            });
            f.publisherIDs = addPublisherIDs;
            await this._saveFilter(f);
            f.closePublisherIDPopover = new Date().valueOf();
            f.addPublisherID = undefined;
            f.focusPublisherIDs = false;
        } catch (err) {
            console.log(err);
        }
    }

    async removePublisherID(f, index) {
        try {
            f.publisherIDs.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    leadSourceOpened(f) {
        f.focusLeadSources = true;
    }

    leadSourceClosed(f) {
        f.focusLeadSources = false;
    }

    async leadSourceEntered(f) {
        try {
            const leadSources = f.addLeadSource.split(',');
            const addLeadSources = JSON.parse(JSON.stringify(f.leadSources));
            leadSources.forEach(x => {
                if (addLeadSources.includes(x)) return;
                addLeadSources.push(x);
            });
            f.leadSources = addLeadSources;
            await this._saveFilter(f);
            f.closeLeadSourcePopover = new Date().valueOf();
            f.addLeadSource = undefined;
            f.focusLeadSources = false;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSource(f, index) {
        try {
            f.leadSources.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }

    async leadTypeSelected(f) {
        try {
            if (!f.leadTypes.includes(f.addLeadType)) f.leadTypes.push(f.addLeadType);
            await this._saveFilter(f);
            f.closeLeadTypePopover = new Date().valueOf();
            f.addLeadType = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadType(f, index) {
        try {
            f.leadTypes.splice(index, 1);
            await this._saveFilter(f);
        } catch (err) {
            console.log(err);
        }
    }


    async _saveFilter(f) {
        const filters = { counties: f.counties, states: f.states, zipCodes: f.zipCodes, publisherIDs: f.publisherIDs, leadSources: f.leadSources, leadTypes: f.leadTypes };
        const updateFilters = JSON.parse(JSON.stringify(filters));
        updateFilters.counties = updateFilters.counties.map(x => x.fips);
        await this._leads.setFilters(f.id, updateFilters);
    }
}