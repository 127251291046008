import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class ScriptOptionDialog {
    static inject = [DialogController, NewInstance.of(ValidationController)];
    dialogController;
    validationController;

    name;
    goToOption;
    goToOptions;

    constructor(dialogController, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('name').required()
            .on(this);
    }

    activate(model) {
        model = model ?? {};
        this.name = model.name ?? '';
        this.goToOption = model.goToOption;
        this.goToOptions = model.goToOptions;
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            const toScriptId = this.goToOption ? this.goToOption.id : undefined;
            this.dialogController.ok({ name: this.name, toScriptId });
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }
}
