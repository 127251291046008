import { observable } from 'aurelia-framework';
export class ImportLeads {
    @observable({ changeHandler: '_setQs' }) sendWebhook = false;
    importLeadQuerystring = '';

    _setQs() {
        const qs = [];
        if (this.sendWebhook) qs.push('param1=yes');
        else qs.push('param1=no');
        this.importLeadQuerystring = qs.join('&');
    }
}