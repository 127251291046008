import { DialogController } from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {Notifier} from 'common/ui';

export class InactivateMember {
    static inject = [DialogController, EventAggregator, Api, Notifier];

    memberId = null;
    newParentEmail = '';
    reason = 'Inactive';
    sendEmail = false;
    requireNewDirectUpline = true;

    constructor(dialogController, eventAggregator, api, notifier) {
        this.dialogController = dialogController;
        this.eventAggregator = eventAggregator;
        this.api = api;
        this.notifier = notifier;
    }

    activate(model) {
        this.memberId = model.memberId;
    }

    async attached() {
        await this.loadMember();
    }

    async loadMember() {
        try {
            this.requireNewDirectUpline = false;
            const data = await this.api.getData('member/' + this.memberId);
            this.requireNewDirectUpline = data.numberOfFirstLineChildren > 0;
        } catch (err) {
            console.log(err);
        }
    }

    async tryInactivate() {
        if (this.requireNewDirectUpline && this.newParentEmail.length === 0) {
            this.notifier.error('profile-inactivate-no-direct-upline');
            return;
        }

        const model = {
            newDirectUplineEmail: this.newParentEmail,
            reason: this.reason,
            sendEmail: this.sendEmail
        };

        try {
            const data = await this.api.post('member/' + this.memberId + '/inactivate', model);
            if (!data.success) {
                this.notifier.errorText(data.failureReason);
                return;
            }
            this.notifier.successText('The user has been marked as ' + this.reason + ' and all first line team members have been assigned to ' + this.newParentEmail + '. Please Note: This process may take up to 15 minutes to complete. Refresh the page to see the updated status.');
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this.notifier.generalError();
        }
    }

}
