import { computedFrom } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { PrintTemplates as PrintTemplatesSvc } from 'services/print-templates';
import { Page } from 'common/ui';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { HtmlEditorDialog } from 'resources/elements/html-editor/html-editor-dialog';
import { UiTermEditorDialog } from 'resources/elements/ui-term/ui-term-editor-dialog';
import { ABOUT_TYPE } from 'common/constants';
import { Leads } from 'services/leads';
import { c } from 'common/common';

PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('resources/elements/html-editor/html-editor-dialog');
PLATFORM.moduleName('resources/elements/ui-term/ui-term-editor-dialog');

export class PrintTemplates {
    static inject = [DialogService, EventAggregator, PrintTemplatesSvc, Page, Api, I18n, Leads];
    _dialogService;
    _ea;
    _printTemplates;
    _page;
    _api;
    _i18n;
    _leads;

    _printType = 'pdf';

    leadSources = [];
    leadSuppliers = [];

    filters = [
        { key: 'search', value: '', keys: ['name'] },
    ];

    constructor(dialogService, ea, printTemplates, page, api, i18n, leads) {
        this._dialogService = dialogService;
        this._ea = ea;
        this._printTemplates = printTemplates;
        this._page = page;
        this._api = api;
        this._i18n = i18n;
        this._leads = leads;
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    activate(model) {
        if (model && model.printType) this._printType = model.printType;
        this._load();
        this._setTitle();
    }

    attached() {
        this._setTitle();
    }

    _setTitle() {
        // Run after all other tasks have completed - the nav changed needs to run first
        window.setTimeout(() => this._ea.publish(c.EventKeys.site.setPageTitle, { title: this._i18n.tr(`lead-${this._printType}-templates`), description: this._i18n.tr(`lead-${this._printType}-templates-description`) }), 0);
    }

    @computedFrom('_printType')
    get showPdfOptions() {
        return this._printType === 'pdf';
    }

    async _load(expandId) {
        try {
            this.templates = await this._printTemplates.byAboutType(this._printType, ABOUT_TYPE.Lead);
            this.templates.forEach(t => {
                t.actions = [];
                if (this.showPdfOptions) t.actions.push({ key: 'sample-pdf', name: 'lead:download-sample-pdf', icon: 'fa-duotone fa-file-pdf' });
                t.actions.push({ key: 'edit-html', name: 'lead:edit-print-template-html', icon: 'fa-duotone fa-file-code' });
                t.actions.push({ key: 'copy-template', name: 'lead:copy-print-template', icon: 'fa-duotone fa-copy' });
                t.actions.push({ key: 'delete-template', name: 'delete', icon: 'fa-duotone fa-trash-can' });
            });
            if (expandId) {
                this.templates.find(x => x.id === expandId).expanded = true;
            }
            await this._loadFilterOptions();
        } catch (err) {
            console.log(err);
        }
    }

    async _loadFilterOptions() {
        try {
            this.filterOptions = await this._printTemplates.filterOptions(ABOUT_TYPE.Lead);
        } catch (err) {
            console.log(err);
        }
    }

    addTemplate() {
        const model = { key: 'lead:add-lead-print-template', okButtonClass: 'btn-primary', inputLabel: 'name', expanded: true };
        this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled || !response.output.value) return;
            const data = await this._printTemplates.save(undefined, this._printType, ABOUT_TYPE.Lead, response.output.value);
            this._load(data.id);
        });
    }

    templateAction(key, t, index) {
        switch (key) {
            case 'delete-template': this._deleteTemplate(t, index); break;
            case 'edit-html': this.editHtml(t); break;
            case 'sample-pdf': this._exportSamplePdf(t); break;
            case 'copy-template': this._copyTemplate(t); break;
        }
    }

    async _copyTemplate(t) {
        try {
            const data = await this._printTemplates.copy(t.id);
            this._load(data.id);
        } catch (err) {
            console.log(err);
        }
    }

    async _exportSamplePdf(t) {
        const model = { ids: null, exportType: 'sample', printTemplateId: t.id, inline: true };
        await this._page.export(this._api, 'leads/export/pdf', model, true);
    }

    async editHeader() {
        const html = await this._printTemplates.getHtml('lead-print-template-header-html');
        const model = { title: 'lead:edit-lead-template-header', html, callback: { id: 'lead-print-template-header-html', fn: this._saveHeaderFooter }, variables: this._leads.printTemplateVariables(true) };
        this._dialogService.open({ viewModel: HtmlEditorDialog, model, ignoreTransitions: true });
    }

    async editFooter() {
        const html = await this._printTemplates.getHtml('lead-print-template-footer-html');
        const model = { title: 'lead:edit-lead-template-footer', html, callback: { id: 'lead-print-template-footer-html', fn: this._saveHeaderFooter }, variables: this._leads.printTemplateVariables(true) };
        this._dialogService.open({ viewModel: HtmlEditorDialog, model, ignoreTransitions: true });
    }

    _saveHeaderFooter = async(id, html) => {
        switch (id) {
            case 'lead-print-template-header-html':
                await this._printTemplates.saveHeaderFooterHtml('leads/export/pdf-header', html);
                break;
            case 'lead-print-template-footer-html':
                await this._printTemplates.saveHeaderFooterHtml('leads/export/pdf-footer', html);
                break;
        }
    }

    async editVariable(key) {
        const value = this._i18n.tr(`lead:${key}`);
        const model = { title: `lead:edit-${key}`, value, callback: { id: key, fn: this._saveVariable } };
        this._dialogService.open({ viewModel: UiTermEditorDialog, model, ignoreTransitions: true });
    }

    _saveVariable = async(id, value) => {
        try {
            await this._printTemplates.saveVariable('lead', id, value);
        } catch (err) {
            console.log(err);
        }
    }

    editHtml(t) {
        const model = { title: t.name, html: t.html, callback: { id: t.id, fn: this._saveHtml }, variables: this._leads.printTemplateVariables() };
        this._dialogService.open({ viewModel: HtmlEditorDialog, model, ignoreTransitions: true });
    }

    _saveHtml = async(id, html) => {
        try {
            const response = await this._printTemplates.saveHtml(id, html);
            const pt = this.templates.find(x => x.id === id);
            pt.html = response.html;
            pt.loadedHtml = response.loadedHtml;
        } catch (err) {
            console.log(err);
        }
    }

    async _deleteTemplate(t, index) {
        try {
            await this._printTemplates.delete(t.id);
            this.templates.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }

    editName(t) {
        t.currentName = t.name;
        t.editingName = true;
    }

    cancelEditName(t) {
        t.name = t.currentName;
        t.currentName = undefined;
        t.editingName = false;
    }

    async saveName(t) {
        try {
            await this._printTemplates.save(t.id, this._printType, ABOUT_TYPE.Lead, t.name);
            t.currentName = undefined;
            t.editingName = false;
        } catch (err) {
            console.log(err);
        }
    }

    async setDefault(t) {
        try {
            await this._printTemplates.setDefault(this._printType, ABOUT_TYPE.Lead, t.id);
            this.templates.forEach(t => t.isDefault = false);
            t.isDefault = true;
        } catch (err) {
            console.log(err);
        }
    }

    async leadSourceSelected(t) {
        try {
            if (!t.filter.leadSources.includes(t.addLeadSource)) t.filter.leadSources.push(t.addLeadSource);
            await this._saveFilter(t);
            t.closeLeadSourcePopover = new Date().valueOf();
            t.addLeadSource = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSource(t, index) {
        try {
            t.filter.leadSources.splice(index, 1);
            await this._saveFilter(t);
        } catch (err) {
            console.log(err);
        }
    }

    async leadSupplierSelected(t) {
        try {
            if (!t.filter.leadSuppliers.includes(t.addLeadSupplier)) t.filter.leadSuppliers.push(t.addLeadSupplier);
            await this._saveFilter(t);
            t.closeLeadSupplierPopover = new Date().valueOf();
            t.addLeadSupplier = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSupplier(t, index) {
        try {
            t.filter.leadSuppliers.splice(index, 1);
            await this._saveFilter(t);
        } catch (err) {
            console.log(err);
        }
    }

    async _saveFilter(t) {
        await this._printTemplates.saveFilter(t.id, t.filter);
    }
}