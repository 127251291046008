import { DialogController } from 'aurelia-dialog';
import { Leads } from 'services/leads';

export class AssignLead {
    static inject = [DialogController, Leads];

    includeHistory = false;

    constructor(dialogController, leads) {
        this.dialogController = dialogController;
        this._leads = leads;
    }

    activate(model) {
        this.leadIds = model.leadIds;
        this.assignToAgentId = model.assignToAgentId;
    }

    async assign() {
        try {
            await this._leads.assignLeads(this.assignToAgentId, this.leadIds, this.includeHistory);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }

}
