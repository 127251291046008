import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Kpis } from 'services/kpis';

export class EditKpiTemplate {
    static inject = [DialogController, NewInstance.of(ValidationController), Kpis]
    dialogController;
    _validationController;
    _kpis;

    kpi;
    kpiGoal;

    constructor(dialogController, validationController, kpis) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._validationController = validationController;
        this._validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._kpis = kpis;

        ValidationRules
            .ensure(m => m.kpiGoal).required().satisfiesRule('integerRange', 1, 999999999)
            .on(this);
    }

    activate(model) {
        this.kpi = model;
        this.kpiGoal = model.kpiGoal;
        this._validationController.reset();
    }

    async save() {
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;
            await this._kpis.updateTemplate(this.kpi, Number(this.kpiGoal));
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
}
