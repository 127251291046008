import { Leads } from 'services/leads';
import { Geography } from 'services/geography';
import { I18n } from 'common/i18n';

export class Opportunities {
    static inject = [Leads, Geography, I18n];
    _leads;
    _geography;
    _i18n;

    view = 'opportunities';
    selectedUsCounties = [];

    constructor(leads, geography, i18n) {
        this._leads = leads;
        this._geography = geography;
        this._i18n = i18n;
    }

    async activate() {
        this._initializeGrid();
        await this._loadGeojson();
    }

    async _loadGeojson() {
        try {
            this.usStatesGeojson = await this._geography.geojson.usStates();
            this.usCountiesGeojson = await this._geography.geojson.usCounties();
        } catch (err) {
            console.log(err);
        }
    }

    attached() {
        this._load();
    }

    showView(view) {
        this.view = view;
        this._load();
    }

    async _load() {
        try {
            let data;
            if (this.view === 'inventory') {
                data = await this._leads.currentInventoryOpportunities();
            } else {
                data = await this._leads.opportunities();
            }
            this.opportunities = data.opportunities;
            this.leadTypes = [];
            data.leadTypes.forEach(lt => {
                if (!lt) return;
                this.leadTypes.push({ checked: true, key: lt });
            });
            this._loadHeatMapData();
            this._loadUsStates();
        } catch (err) {
            console.log(err);
        }
    }

    leadTypeSelected() {
        this._loadHeatMapData();
        this._loadLeadsForCounty(true);
    }

    _loadHeatMapData() {
        try {
            const selectedLeadTypes = this.leadTypes.filter(x => x.checked).map(x => x.key);
            const heatMapData = [];
            this.opportunities.forEach(o => {
                if (!o.point || !o.point.latitude || !o.point.longitude) return;
                if (!selectedLeadTypes.includes(o.leadType)) return;
                heatMapData.push([o.point.longitude, o.point.latitude]);
            });
            this.heatMapData = heatMapData;
        } catch (err) {
            console.log(err);
        }
    }

    _geojson(data) {
        return {
            id: 'us-states',
            data,
            style: {
                strokeColor: '#000',
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
                fillColor: 'transparent',
                fillOpacity: 'transparent'
            },
            label: {
                cssClass: 'lpfn-map-label',
                minZoom: 8,
                template: (geojsonFeature) => {
                    return geojsonFeature.properties.name;
                }
            },
            mouseover: {
                setStyle: (feature) => {
                    const fips = feature.getId();
                    if (fips.length === 2) {
                        // US State
                        return { fillColor: 'black', fillOpacity: 0.25 };
                    }
                    // County, only set the style if it isn't already selected
                    if (this.selectedUsCounties.find(x => x.fips === fips)) return;
                    return { fillColor: 'white', fillOpacity: 0.25 };
                }
            },
            click: {
                setStyle: (feature) => {
                    const fips = feature.getId();
                    if (fips.length === 2) {
                        // State, no style set on click
                    } else {
                        // County, set the style on click, the county will already be set from the click callback
                        if (this.selectedUsCounties.find(x => x.fips === fips)) {
                            return { fillColor: 'red', fillOpacity: 0.25 };
                        }
                        return null;
                    }
                },
                zoomTo: true,
                callback: (feature) => {
                    const fips = feature.getId();
                    const name = feature.getProperty('name');
                    if (fips.length === 2) {
                        // This is a state
                        this._loadUsCounties(fips, name);
                    } else {
                        // This is a county
                        const idx = this.selectedUsCounties.findIndex(x => x.fips === fips);
                        if (idx >= 0) this.selectedUsCounties.splice(idx, 1);
                        else this.selectedUsCounties.push({ fips, name });
                        this._loadLeadsForCounty();
                    }
                }
            },
        };
    }

    async _loadUsStates() {
        try {
            this.geojson = this._geojson(this.usStatesGeojson);
        } catch (err) {
            console.log(err);
        }
    }

    async _loadUsCounties(stateFips, stateName) {
        try {
            let usStateFeature;
            this.selectedState = stateName;
            if (this.selectedStateFips) {
                // need to add the state back into the map
                usStateFeature = this.usStatesGeojson.features.find(x => x.id === this.selectedStateFips);
            }
            let removeIds = [];
            if (this.stateCounties) {
                removeIds = this.stateCounties.map(x => x.fips);
            }
            this.selectedStateFips = stateFips;
            removeIds.push(stateFips);
            this.removeIds = removeIds;

            this.stateCounties = await this._geography.usCounties(stateName);
            const countyFips = this.stateCounties.map(x => x.fips);
            const data = JSON.parse(JSON.stringify(this.usCountiesGeojson));
            data.features = this.usCountiesGeojson.features.filter(x => countyFips.includes(x.id));
            if (usStateFeature) data.features.push(usStateFeature);
            this.geojson = this._geojson(data);
        } catch (err) {
            console.log(err);
        }
    }

    async _loadLeadsForCounty(filterExisting = false) {
        try {
            this.loading = true;
            if (!filterExisting) {
                const selectedCountyNames = this.selectedUsCounties.map(x => x.name);
                this.selectedCounties = selectedCountyNames.join(', ');
                const counties = this.selectedUsCounties && this.selectedUsCounties.length ? this.selectedUsCounties.map(x => x.fips) : undefined;

                if (this.view === 'inventory') {
                    this.countyLeadsData = await this._leads.currentInventoryOpportunities(counties);
                } else {
                    this.countyLeadsData = await this._leads.opportunities(counties);
                }
            }
            const countyLeads = [];
            const selectedLeadTypes = this.leadTypes.filter(x => x.checked).map(x => x.key);
            this.countyLeadsData.opportunities.forEach(o => {
                if (!selectedLeadTypes.includes(o.leadType)) return;
                o.leadTypeName = this._i18n.tr(`lead:type-${o.leadType}`);
                countyLeads.push(o);
            });
            this.countyLeads = countyLeads;
            this._loadSelectedCountyZips();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _loadSelectedCountyZips() {
        try {
            const selectedFips = this.selectedUsCounties.map(x => x.fips);
            this.zips = await this._geography.zips(selectedFips);
            this.selectedUsCountiesZips = this.zips.join(',');
        } catch (err) {
            console.log(err);
        }
    }

    _initializeGrid() {
        this.cols = [
            {
                field: 'receivedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('lead:added'),
                cellDataType: 'dateString',
                enableRowGroup: true,
            },
            {
                field: 'leadTypeName',
                headerName: this._i18n.tr('lead-type'),
                enableRowGroup: true,
            },
            {
                field: 'county',
                headerName: this._i18n.tr('county'),
                enableRowGroup: true,
            },
            {
                field: 'city',
                headerName: this._i18n.tr('city'),
                enableRowGroup: true,
            },
            {
                field: 'state',
                headerName: this._i18n.tr('state'),
                enableRowGroup: true,
            },
            {
                field: 'zip',
                headerName: this._i18n.tr('zip'),
                enableRowGroup: true,
            },
        ];
    }
}
