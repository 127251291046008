import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { Levels } from 'services/levels';
import { c } from 'common/common';

export class SetLevel {
    static inject = [DialogController, EventAggregator, Api, Notifier, Levels];
    _levels;
    memberId = null;
    member = null;
    levels = [];

    constructor(dialogController, eventAggregator, api, notifier, levels) {
        this.dialogController = dialogController;
        this._ea = eventAggregator;
        this.api = api;
        this.notifier = notifier;
        this._levels = levels;
    }

    activate(model) {
        this.memberId = model.memberId;
    }

    async attached() {
        try {
            this.member = await this.api.get('member/' + encodeURIComponent(this.memberId));
            const data = await this._levels.byType();
            this.lifeLevels = data.lifeLevels;
            this.annuityLevels = data.annuityLevels;
            this.hmaLevels = data.hmaLevels;
        } catch (err) {
            console.log(err);
            this.notifier.generalError();
        }
    }

    async trySetLevel(levelType, memberProperty) {
        const model = {
            id: this.memberId,
            levelType,
            level: this.member[memberProperty],
        };

        try {
            await this.api.post('admin/set-level', model);
            this.notifier.successText('The level has been set');
            this._ea.publish(c.EventKeys.memberUpdated, { memberId: this.memberId });
        } catch (err) {
            console.log(err);
            this.notifier.generalError();
        }
    }

}
