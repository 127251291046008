import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';
import { ROLE } from 'common/constants';

export class Index extends RouterConfigBase {
	static inject = [EventAggregator, Security, Menus];

	constructor(ea, security, menus) {
        super(ea, security, menus, undefined, false);
	}

	configureRouter(config, router){
		config.map([
            {
                route: ['', 'pool'],
                moduleId: PLATFORM.moduleName('admin/leads/lead-pool-grid'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadManager], title: 'Lead Pool', description: 'Manage the lead pool' },
            },
            {
                route: 'auto-assign-filters',
                moduleId: PLATFORM.moduleName('admin/leads/auto-assign-filters'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadAdmin], title: 'Auto Assign Filters', description: 'Filters used to auto assign leads when received' },
            },
            {
                route: ['print-templates', 'print-templates/:printType'],
                moduleId: PLATFORM.moduleName('admin/leads/print-templates'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadAdmin], title: 'Lead Print Templates', description: 'Print templates to view and print leads' },
            },
            {
                route: 'sources',
                moduleId: PLATFORM.moduleName('admin/leads/sources'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadAdmin], title: 'Lead Sources', description: 'Manage lead sources' },
            },
            {
                route: 'scripts',
                moduleId: PLATFORM.moduleName('admin/leads/scripts'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadAdmin], title: 'Lead Scripts', description: 'Manage lead scripts' },
            },
            {
                route: 'import',
                moduleId: PLATFORM.moduleName('admin/leads/import-leads'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadManager], title: 'Import Leads', description: 'Import leads into the lead pool' },
            },
            {
                route: 'opportunities',
                moduleId: PLATFORM.moduleName('admin/leads/opportunities'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.LeadAdmin], title: 'Opportunities', description: 'Incoming leads that were rejected' },
            },
		]);

		this.router = router;
	}

	attached() {
		this._attached();
	}

	detached() {
		this._detached();
	}
}
