import { DialogController } from 'aurelia-dialog';
import { Notifier } from 'common/ui';
import { Api } from 'common/server';

export class AssignRoles {
    static inject = [DialogController, Notifier, Api];
    id;
    userRoles;

    constructor(dialogController, notifier, api) {
        this.dialogController = dialogController;
        this._notifier = notifier;
        this._api = api;
    }

    activate(model) {
        this.id = model.memberId;
    }

    async attached() {
        await this._load();
    }

    async _load() {
        this.userRoles = await this._api.get(`member/${encodeURIComponent(this.id)}/roles`);
    }

    async toggleUserRole(userRole) {
        try {
            const url = `member/${encodeURIComponent(this.id)}/${!userRole.isInRole ? 'assign-to-role' : 'remove-from-role'}/${encodeURIComponent(userRole.roleId)}`;
            await this._api.post(url);
            // userRole.isInRole = !userRole.isInRole;
            this._notifier.success('roles-assign-success');
        } catch (err) {
            this._notifier.generalError();            
        }
    }
}
