import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { c } from 'common/common';

export class ChangeParent {
    static inject = [DialogController, EventAggregator, Api, Notifier];

	memberId = null;
	newParentEmail = '';

	constructor(dialogController, eventAggregator, api, notifier) {
        this.dialogController = dialogController;
		this._ea = eventAggregator;
		this.api = api;
		this.notifier = notifier;
	}

    activate(model) {
        this.memberId = model.memberId;
    }

	async activateMember() {
		try {
		    await this.api.get('admin/activate-member?id=' + encodeURIComponent(this.memberId) + '&newParentEmail=' + encodeURIComponent(this.newParentEmail));
            this.notifier.success(this.newParentEmail ? 'activate-agent-with-direct-upline-success' : 'activate-agent-success', { directUplineEmail: this.newParentEmail });
            this._ea.publish(c.EventKeys.memberUpdated, { memberId: this.memberId });
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this.notifier.generalError();
        }
	}

}
