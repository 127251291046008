import { PLATFORM } from 'aurelia-pal';
import { Kpis } from 'services/kpis';
import { EditKpiTemplate } from './dialog/edit-kpi-template';
import { DialogService } from 'aurelia-dialog';
PLATFORM.moduleName('./dialog/edit-kpi-template');

export class KpiTemplates {
    static inject = [Kpis, DialogService];
    _kpis;
    _dialogService;

    templates;

    constructor(kpis, dialogService) {
        this._kpis = kpis;
		this._dialogService = dialogService;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.templates = await this._kpis.templates();
        } catch (err) {
            console.log(err);
        }
    }

    async toggleKpiActive(kpi) {
        try {
            await this._kpis.activateTemplate(kpi, !kpi.isActive);
        } catch (err) {
            console.log(err);
        }
    }

    openEditor(kpi) {
	    this._dialogService.open({ viewModel: EditKpiTemplate, model: kpi }).whenClosed(response => {
	        if (response.wasCancelled) return;
            this._load();
	    });
    }
}
