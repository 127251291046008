import { PLATFORM } from 'aurelia-pal';
import { computedFrom, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { Leads } from 'services/leads';
import { DialogService } from 'aurelia-dialog';
import { AssignLead } from './dialogs/assign-lead';
import { FormatUrlValueConverter } from 'resources/value-converters/format-url';
import { TitleCaseValueConverter } from 'resources/value-converters/title-case';
import { Page } from 'common/ui';
import { Api } from 'common/server';
import { c } from 'common/common';
import { LinkCallbackRenderer, LinkCellRenderer, ButtonCallbackRenderer } from 'resources/elements/analytics/analytics-cell-renderers';
import { ROLE, ABOUT_TYPE } from 'common/constants';
import environment from '../../../config/environment.json';

PLATFORM.moduleName('./dialogs/assign-lead');

export class LeadPoolGrid {
    static inject = [EventAggregator, Security, I18n, Leads, DialogService, FormatUrlValueConverter, TitleCaseValueConverter, Page, Api];
    _ea;
    _security;
    _i18n;
    _leads;
    _dialogService;
    _formatUrlValueConverter;
    _titleCase;
    _page;
    _api;

    cols;
    leads;
    @observable assignToAgent;
    selectedIds = [];
    isViewingAgentAssignedLeads = false;

    _handlers = [];

    constructor(ea, security, i18n, leads, dialogService, formatUrlValueConverter, titleCase, page, api) {
        this._ea = ea;
        this._security = security;
        this._i18n = i18n;
        this._leads = leads;
        this._dialogService = dialogService;
        this._formatUrlValueConverter = formatUrlValueConverter;
        this._titleCase = titleCase;
        this._page = page;
        this._api = api;
    }

    activate() {
        this._initializeGrid();
        this._load();
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.profileUpdated, (data) => this._onLeadUpdated(data, true)));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _onLeadUpdated(data) {
        this.data = undefined;
        this._load();
    }

    @computedFrom('assignToAgent', 'selectedIds', 'isViewingAgentAssignedLeads')
    get canAssign() {
        return this.assignToAgent && this.selectedIds.length && !this.isViewingAgentAssignedLeads;
    }

    @computedFrom('selectedIds', 'isViewingAgentAssignedLeads', 'isViewingAssignedLeadPool')
    get canUnassign() {
        return this.selectedIds.length && (this.isViewingAgentAssignedLeads || this.isViewingAssignedLeadPool);
    }

    @computedFrom('assignToAgent', 'isViewingAgentAssignedLeads')
    get canViewAssigned() {
        return this.assignToAgent && !this.isViewingAgentAssignedLeads;
    }

    @computedFrom('isViewingAgentAssignedLeads', 'isViewingAssignedLeadPool')
    get canViewPool() {
        return this.isViewingAgentAssignedLeads || this.isViewingAssignedLeadPool;
    }

    @computedFrom('assignToAgent', 'selectedIds', 'isViewingAgentAssignedLeads', 'isViewingAssignedLeadPool')
    get canViewAssignedLeadPool() {
        return !this.assignToAgent && !this.selectedIds.length && !this.isViewingAgentAssignedLeads && !this.isViewingAssignedLeadPool;
    }

    @computedFrom('selectedIds', 'isViewingAgentAssignedLeads', 'isViewingAssignedLeadPool', 'assignToAgent')
    get canDeleteSelected() {
        if (!this.selectedIds || !this.selectedIds.length) return false;
        return !this.isViewingAgentAssignedLeads && !this.isViewingAssignedLeadPool && !this.assignToAgent;
    }

    assignToAgentChanged() {
        if (!this.assignToAgent && this.isViewingAgentAssignedLeads) {
            this.viewLeadPool();
        }
        if (this.assignToAgent && this.isViewingAgentAssignedLeads) {
            this.viewAssignedLeads();
        }
    }

    onSelected(data) {
        this.selectedIds = data.ids;
    }

    async deleteLeads() {
        try {
            const response = await this._leads.delete(this.selectedIds);
            this._load();
        } catch (err) {
            console.log(err);
        }
    }

    async assignLeads() {
        try {
            if (!this.canAssign) return;

            if (this.isViewingAssignedLeadPool) {
                const model = { leadIds: this.selectedIds, assignToAgentId: this.assignToAgent.id };
                this._dialogService.open({ viewModel: AssignLead, model, ignoreTransitions: true }).whenClosed(async(response) => {
                    if (response.wasCancelled) return;
                    this.leads = [];
                    this._load();
                });
                return;
            } else {
                await this._leads.assignLeads(this.assignToAgent.id, this.selectedIds);
                this.leads = [];
                this._load();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async unassignLeads() {
        try {
            if (!this.canUnassign) return;
            await this._leads.unassignLeads(this.selectedIds);
            this.leads = [];
            this._load();
        } catch (err) {
            console.log(err);
        }
    }

    viewAssignedLeads() {
        if (!this.assignToAgent) return;
        this.isViewingAgentAssignedLeads = true;
        this._ea.publish(c.EventKeys.site.setPageTitle, { title: this._i18n.tr('lead:agent-lead-pool', { name: this.assignToAgent.fullName}) });
        this._load();
    }

    viewLeadPool() {
        this.isViewingAgentAssignedLeads = false;
        this.assignToAgent = undefined;
        this.isViewingAssignedLeadPool = false;
        this.groupByColumn = undefined;
        this._ea.publish(c.EventKeys.site.setPageTitle, { title: 'lead:lead-pool' });
        this._load();
    }

    viewAssignedLeadPool() {
        this.isViewingAssignedLeadPool = true;
        this.groupByColumn = 'assignedToAgent';
        this._load();
    }

    reload() {
        this._load();
    }

    async _load() {
        try {
            this.loading = true;
            this.leads = this.isViewingAgentAssignedLeads
                ? await this._leads.forAgent(this.assignToAgent.id)
                : await this._leads.pool(this.isViewingAssignedLeadPool);
            this.leads.forEach(l => {
                l.fullName = this._titleCase.toView(l.fullName);
                l.typeValue = l.type ? this._i18n.tr(`lead:type-${l.type}`) : '';
                l.receivedAgeValue = l.receivedAge ? this._i18n.tr(`lead:age-${l.receivedAge}`) : '';
                l.statusValue = l.status ? this._i18n.tr(`lead-status-${l.status}`) : '';
                l.dispositionValue = l.disposition ? this._i18n.tr(`lead:disposition-${l.disposition}`) : '';
                l.leadNameCallback = () => this._onNameClick(l.id);
                l.leadPdfCallback = () => this._onPdfClick(l.id);
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async exportLeads() {
        const model = { ids: this.selectedIds, exportType: null, assignedToAgentId: this.assignToAgent?.id };
        if (!this.selectedIds.length) {
            if (this.isViewingAssignedLeadPool) model.exportType = 'assigned-lead-pool';
            else model.exportType = 'lead-pool';
        }
        await this._page.export(this._api, 'leads/export/list', model);
    }

    async exportLeadSuppressionList() {
        const model = { ids: this.selectedIds };
        await this._page.export(this._api, 'leads/export/suppression-list', model);
    }

    _onNameClick(leadId) {
        this._ea.publish(c.EventKeys.site.openLead, { leadId });
    }

    _onPdfClick(leadId) {
        console.log('upload pdf for ', leadId);
        const qs = [];
        qs.push(`forType=${encodeURIComponent(ABOUT_TYPE.Lead)}`);
        qs.push(`forId=${encodeURIComponent(leadId)}`);
        qs.push(`fileType=${encodeURIComponent('lead-record')}`);
        this.pdfUploadUrl = `${environment.api}/api/file-for/upload?${qs.join('&')}`;
        this.openPdfUploader = new Date().valueOf();
    }

    pdfUploaded() {
        this.pdfUploadUrl = undefined;
    }

    _urlFormatter = (value) => {
        return this._formatUrlValueConverter.toView(value);
    }

    _initializeGrid() {
        this.cols = [
            {
                field: 'fullName',
                headerName: this._i18n.tr('name'),
                enableRowGroup: false,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'leadNameCallback' },
            },
            {
                field: 'createdDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('lead:added'),
                cellDataType: 'dateString',
            },
            {
                field: 'powerScore',
                type: 'numericColumn',
                headerName: this._i18n.tr('lead:power-score-abbr'),
                enableRowGroup: false,
                cellDataType: 'number'
            },
            {
                headerName: this._i18n.tr('file-for-type-lead-record'),
                cellRenderer: ButtonCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'leadPdfCallback', buttonText: this._i18n.tr('upload-lead-record') },
            },
            {
                field: 'assignedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('lead:assigned'),
                cellDataType: 'dateString',
            },
            {
                field: 'lastWorkedDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('lead:last-worked-date'),
                cellDataType: 'dateString',
            },
            {
                field: 'assignedToAgent',
                headerName: this._i18n.tr('lead:assigned-to'),
                enableRowGroup: true,
            },
            {
                field: 'statusValue',
                headerName: this._i18n.tr('lead:status'),
                enableRowGroup: true,
            },
            {
                field: 'doNotCallDate',
                type: 'dateColumn',
                headerName: this._i18n.tr('lead:do-not-call'),
                cellDataType: 'dateString',
                enableRowGroup: true,
            },
            {
                field: 'dispositionValue',
                headerName: this._i18n.tr('lead:disposition'),
                enableRowGroup: true,
            },
            {
                field: 'source',
                headerName: this._i18n.tr('lead:source'),
                enableRowGroup: true,
            },
            {
                field: 'typeValue',
                headerName: this._i18n.tr('lead:type'),
                enableRowGroup: true,
            },
            {
                field: 'product',
                headerName: this._i18n.tr('lead:product'),
                enableRowGroup: true,
            },
            {
                field: 'receivedAgeValue',
                headerName: this._i18n.tr('lead:age'),
                enableRowGroup: true,
            },
            {
                field: 'address',
                headerName: this._i18n.tr('address'),
                enableRowGroup: false,
            },
            {
                field: 'city',
                headerName: this._i18n.tr('city'),
                enableRowGroup: true,
            },
            {
                field: 'state',
                headerName: this._i18n.tr('state'),
                enableRowGroup: true,
            },
            {
                field: 'county',
                headerName: this._i18n.tr('county'),
                enableRowGroup: true,
            },
            {
                field: 'zipCode',
                headerName: this._i18n.tr('zip'),
                enableRowGroup: true,
            },
            {
                field: 'email',
                headerName: this._i18n.tr('lead:email'),
                enableRowGroup: false,
            },
            {
                field: 'phone',
                headerName: this._i18n.tr('lead:phone'),
                enableRowGroup: false,
            },
            {
                field: 'coverageType',
                headerName: this._i18n.tr('lead:coverage-type'),
                enableRowGroup: true,
            },
            {
                field: 'coverageTerm',
                headerName: this._i18n.tr('lead:coverage-term'),
                enableRowGroup: false,
            },
            {
                field: 'coverageAmount',
                type: 'numericColumn',
                headerName: this._i18n.tr('lead:coverage-amount'),
                enableRowGroup: false,
                cellDataType: 'money',
                filter: 'agNumberColumnFilter'
            },
            {
                field: 'coverageRange',
                headerName: this._i18n.tr('lead:coverage-amount'),
                enableRowGroup: false,
            },
            {
                field: 'sourceMetadata.url',
                headerName: this._i18n.tr('lead:source'),
                enableRowGroup: true,
                cellRenderer: LinkCellRenderer,
                cellRendererParams: { urlFormatter: this._urlFormatter },
            },
            {
                field: 'sourceMetadata.publisherID',
                headerName: this._i18n.tr('lead:publisher-id'),
                enableRowGroup: true,
            },
            {
                field: 'sourceMetadata.subID',
                headerName: this._i18n.tr('lead:sub-id'),
                enableRowGroup: true,
            },
            {
                field: 'sourceMetadata.campaignID',
                headerName: this._i18n.tr('lead:campaign-id'),
                enableRowGroup: true,
            },
            {
                field: 'sourceMetadata.jornayaId',
                headerName: this._i18n.tr('lead:jornaya-lead-id'),
                enableRowGroup: false,
            },
            {
                field: 'sourceMetadata.trustedForm',
                headerName: this._i18n.tr('lead:trusted-form'),
                enableRowGroup: false,
                cellRenderer: LinkCellRenderer,
                cellRendererParams: { urlFormatter: this._urlFormatter },
            },
        ];

        if (this._security.isInRole(ROLE.LeadAdmin)) {
            this.cols.push({
                field: 'leadSupplier',
                headerName: this._i18n.tr('lead:supplier'),
                enableRowGroup: true,
            });
        }
    }
}