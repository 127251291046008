import { LeadScripts } from 'services/lead-scripts';
import { DialogService } from 'aurelia-dialog';
import { EditScriptDialog } from './dialogs/edit-script-dialog';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
PLATFORM.moduleName('./dialogs/edit-script-dialog');
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class Scripts {
    static inject = [LeadScripts, DialogService];
    _leadScripts;
    _dialogService;

    filters = [
        { key: 'search', value: '', keys: ['name'] },
    ];

    _handlers = [];

    constructor(leadScripts, dialogService) {
        this._leadScripts = leadScripts;
        this._dialogService = dialogService;
    }

    attached() {
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load(expandId) {
        try {
            this.loading = true;
            this.scripts = await this._leadScripts.all();
            this.scripts.forEach(s => {
                s.actions = [
                    { key: 'edit', name: 'lead:edit-script', icon: 'fa-duotone fa-pencil-alt' },
                    { key: 'copy', name: 'lead:copy-script', icon: 'fa-duotone fa-copy' },
                    { key: 'delete', name: 'delete', icon: 'fa-duotone fa-trash-can' },
                ];
            });
            if (expandId) {
                this.scripts.find(x => x.id === expandId).expanded = true;
            }
            await this._loadFilterOptions();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _loadFilterOptions() {
        try {
            this.filterOptions = await this._leadScripts.filterOptions();
        } catch (err) {
            console.log(err);
        }
    }

    addScript() {
        const model = { key: 'lead:add-lead-script', okButtonClass: 'btn-primary', inputLabel: 'name', expanded: true };
        this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled || !response.output.value) return;
            const data = await this._leadScripts.save({ name: response.output.value });
            this._load(data.id);
            this.openEditor(data);
        });
    }

	openEditor(leadScript) {
	    const model = leadScript || {};
	    this._dialogService.open({ viewModel: EditScriptDialog, model: model, ignoreTransitions: true }).whenClosed(response => {
            this._load(leadScript.id);
	    });
	}

    scriptAction(key, s, index) {
        switch (key) {
            case 'delete': this._delete(s, index); break;
            case 'edit': this.openEditor(s); break;
            case 'copy': this._copy(s); break;
        }
    }

    async _copy(s) {
        try {
            const data = await this._leadScripts.copy(s.id);
            this._load(data.id);
        } catch (err) {
            console.log(err);
        }
    }

    async _delete(s, index) {
        try {
            await this._leadScripts.delete(s.id);
            this.scripts.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }

    editName(s) {
        s.currentName = s.name;
        s.editingName = true;
    }

    cancelEditName(s) {
        s.name = s.currentName;
        s.currentName = undefined;
        s.editingName = false;
    }

    async saveName(s) {
        try {
            await this._leadScripts.save({ id: s.id, name: s.name, script: s.script });
            s.currentName = undefined;
            s.editingName = false;
        } catch (err) {
            console.log(err);
        }
    }

    async setDefault(s) {
        try {
            await this._leadScripts.setDefault(s.id);
            this.scripts.forEach(s => s.isDefault = false);
            s.isDefault = true;
        } catch (err) {
            console.log(err);
        }
    }

    async leadSourceSelected(s) {
        try {
            if (!s.filter.leadSources.includes(s.addLeadSource)) s.filter.leadSources.push(s.addLeadSource);
            await this._saveFilter(s);
            s.closeLeadSourcePopover = new Date().valueOf();
            s.addLeadSource = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSource(s, index) {
        try {
            s.filter.leadSources.splice(index, 1);
            await this._saveFilter(s);
        } catch (err) {
            console.log(err);
        }
    }

    async leadSupplierSelected(s) {
        try {
            if (!s.filter.leadSuppliers.includes(s.addLeadSupplier)) s.filter.leadSuppliers.push(s.addLeadSupplier);
            await this._saveFilter(s);
            s.closeLeadSupplierPopover = new Date().valueOf();
            s.addLeadSupplier = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSupplier(s, index) {
        try {
            s.filter.leadSuppliers.splice(index, 1);
            await this._saveFilter(s);
        } catch (err) {
            console.log(err);
        }
    }

    async _saveFilter(s) {
        await this._leadScripts.saveFilter(s.id, s.filter);
    }
}
