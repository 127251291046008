import { DialogController } from 'aurelia-dialog';
import { LEAD_TYPE, LEAD_AGE } from 'services/leads';
import { LeadSources } from 'services/lead-sources';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class EditSource {
    static inject = [DialogController, LeadSources, NewInstance.of(ValidationController)];
    dialogController;
    _leadSources;
    validationController;

    id;
    name;
    supplier;
    campaignID;
    campaignName;
    cost;
    leadType;
    receivedAge;
    product;

    leadTypes = LEAD_TYPE.all();
    ages = LEAD_AGE.all();

    title = '';
    deleteTextKey = 'delete';

    constructor(dialogController, leadSources, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._leadSources = leadSources;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('id').required()
            .ensure('name').required()
            .ensure('cost').required().satisfiesRule('decimalRange', 0, 9999999)
            .ensure('supplier').required()
            .ensure('leadType').required()
            .ensure('receivedAge').required()
            .ensure('product').required()
            .on(this);
    }

    activate(model) {
        model = model || {};
        this.leadSourceId = model.id;
        this.id = model.id;
        this.name = model.name;
        this.campaignID = model.campaignID;
        this.campaignName = model.campaignName;
        this.cost = model.cost;
        this.supplier = model.supplier;
        this.leadType = model.leadType;
        this.receivedAge = model.receivedAge;
        this.product = model.product;
        this.canDelete = model.canDelete;
        this.title = this.id ? 'lead:add-lead-source' : 'lead:edit-lead-source';
        this.validationController.reset();
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            const payload = {
                id: this.id,
                name: this.name,
                cost: this.cost,
                supplier: this.supplier,
                product: this.product,
                receivedAge: this.receivedAge,
                leadType: this.leadType,
                campaignID: this.campaignID,
                campaignName: this.campaignName,
            };
            await this._leadSources.save(payload);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteTextKey = 'delete';
    }

    async delete() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteTextKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._leadSources.delete(this.id);
            this.dialogController.ok();
        } catch(err) {
            this.resetDelete();
            console.log(err);
        } finally {
            this.deleting = false;
        }
    }
}
