import { DialogController } from 'aurelia-dialog';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { c } from 'common/common';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class ResetPasswordNow {
    static inject = [DialogController, Api, Notifier, NewInstance.of(ValidationController)]

	memberId = null;
	password = '';

	constructor(dialogController, api, notifier, validationController) {
        this.dialogController = dialogController;
		this.api = api;
		this.notifier = notifier;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('password').required().minLength(6).matches(c.RegEx.strongPassword)
            .on(this);
	}

	activate(model) {
        this.memberId = model.memberId;
	}

    async reset() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const model = {
                id: this.memberId,
                password: this.password
            };
            await this.api.post('member/reset-password-now', model);
            this.notifier.success('login-reset-confirmation');
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this.notifier.generalError();
        }
	}

}
