import { I18n } from 'common/i18n';
import { LeadSources } from 'services/lead-sources';
import { DialogService } from 'aurelia-dialog';
import { LinkCallbackRenderer } from 'resources/elements/analytics/analytics-cell-renderers';

import { EditSource } from './dialogs/edit-source';
PLATFORM.moduleName('./dialogs/edit-source');

export class Sources {
    static inject = [I18n, LeadSources, DialogService];
    _i18n;
    _leadSources;
    _dialogService;

    filters = [
        { key: 'search', value: '', keys: ['id', 'name', 'supplier', 'receivedAgeValue', 'leadTypeValue', 'product', 'campaignID', 'campaignName'] },
    ];
    filterColumns;
    pageSize = 25;

    _handlers = [];

    constructor(i18n, leadSources, dialogService) {
        this._i18n = i18n;
        this._leadSources = leadSources;
        this._dialogService = dialogService;
    }

    attached() {
        this._load();
        this._initializeGrid();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    reload() {
        this._load();
    }

    async _load() {
        try {
            this.loading = true;
            this.sources = await this._leadSources.all();
            this.sources.forEach(s => {
                s.nameCallback = () => this.editSource(s);
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    editSource(source) {
        this._dialogService.open({ viewModel: EditSource, model: source, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled) return;
            this._load();
        });
    }

    async save() {
        try {
            const payload = {
                id: this.id,
                name: this.name,
                supplier: this.supplier,
                product: this.product,
                receivedAge: this.receivedAge,
                leadType: this.leadType,
                ownerMemberId: this.ownerMemberId,
            };
            const response = await this._leadSources.save(payload);
        } catch (err) {
            console.log(err);
        }
    }

    _initializeGrid() {
        this.cols = [
            {
                field: 'name',
                headerName: this._i18n.tr('name'),
                enableRowGroup: false,
                cellRenderer: LinkCallbackRenderer,
                cellRendererParams: { lpfnCallback: 'nameCallback' },
            },
            {
                field: 'cost',
                headerName: this._i18n.tr('lead:cost'),
                cellDataType: 'number',
            },
            {
                field: 'supplier',
                headerName: this._i18n.tr('lead:supplier'),
                enableRowGroup: true,
            },
            {
                field: 'campaignID',
                headerName: this._i18n.tr('lead-campaign-id'),
                enableRowGroup: true,
            },
            {
                field: 'campaignName',
                headerName: this._i18n.tr('lead-campaign-name'),
                enableRowGroup: true,
            },
            {
                field: 'leadTypeValue',
                headerName: this._i18n.tr('lead:type'),
                enableRowGroup: true,
            },
            {
                field: 'product',
                headerName: this._i18n.tr('lead:product'),
                enableRowGroup: true,
            },
            {
                field: 'receivedAgeValue',
                headerName: this._i18n.tr('lead:age'),
                enableRowGroup: true,
            },
            {
                field: 'leadCount',
                headerName: this._i18n.tr('lead:count'),
                cellDataType: 'number',
            },
        ];
    }
}
