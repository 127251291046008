import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { UiWidgets } from 'services/ui-widgets';
import { c } from 'common/common';

export class LandingPageEditor {
    static inject = [DialogController, NewInstance.of(ValidationController), I18n, Api, Notifier, UiWidgets];
    slug = '';

    constructor(dialogController, validationController, i18n, api, notifier, uiWidgets) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._api = api;
        this._notifier = notifier;
        this._uiWidgets = uiWidgets;

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .on(this);
    }

    activate(model) {
        this.slug = '';
        this.landingPageType = model.type;
        this.validationController.reset();
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const saveModel = { key: this.slug, type: this.landingPageType };

            const data = await this._api.post('landing-page', saveModel);
            for (var w of data.uiWidgets) {
                this._uiWidgets.addToCache(w);
            }
            this._notifier.success(`Landing page ${this.slug} has been saved`);
            this.dialogController.ok({ key: this.slug });
        } catch (err) {
            this._notifier.error('An error occurred');
        }
    }
}
