import { DialogController } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Mentors } from 'services/mentors';
import { Notifier } from 'common/ui';
import { ROLE } from 'common/constants';
import { c } from 'common/common';

export class SetMentor {
    static inject = [DialogController, EventAggregator, Mentors, Notifier];
    dialogController;
    _ea;
    _mentors;
    _notifier;

    memberId = null;
    mentor;
    deleteText = 'admin:remove-mentor';
    showDeleteConfirm = false;
    ROLE = ROLE;

	constructor(dialogController, ea, mentors, notifier) {
        this.dialogController = dialogController;
        this._ea = ea;
		this._mentors = mentors;
		this._notifier = notifier;
	}

    activate(model) {
        this.memberId = model.memberId;
        this._load();
    }

    async _load() {
        try {
            this.currentMentor = await this._mentors.forAgent(this.memberId);
        } catch (err) {
            console.log(err);
        }
    }

	async setMentor() {
		try {
		    await this._mentors.assignMentor(this.memberId, this.mentor.id);
            this._ea.publish(c.EventKeys.mentor.assigned, { mentor: this.mentor });
            this._notifier.success('set-mentor-success');
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
            this._notifier.generalError();
        }
	}

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = 'admin:remove-mentor';
    }

    async deleteMentor() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = 'admin:remove-mentor-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._mentors.removeMentor(this.memberId);
            this._ea.publish(c.EventKeys.mentor.removed, {});
            this._notifier.success('admin:remove-mentor-success');
            this._load();
        } catch(err) {
            this.resetDelete();
        } finally {
            this.deleting = false;
        }
    }
}
